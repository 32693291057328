body,
html {
  margin: 0;
  background-color: #f2efff;
  font-family: "Titillium Web", -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

input {
  margin: 0px 0px 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px 0px 8px;
}

h1 {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.125;
}

h2 {
  font-size: 1.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.3334;
}

h3 {
  font-size: 1.625rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.077;
}

h4 {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.077;
}

h5 {
  font-size: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.01825em;
  line-height: 1.25;
}
h6 {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: normal;
  line-height: normal;
}

p {
  margin: 8px 0px;
}

/*  Hide arrows on number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
